export const CHAINs = {
  'undefined':{
    id:66,
    name:'OEC',
    nativeToken:"OKT",
    tokenName:'Rocket',
    swapLink:'https://www.cherryswap.net/#/swap',
    addLiquidityLink:'https://www.cherryswap.net/#/add',
  },
  '66':{
    id:66,
    name:'OEC',
    nativeToken:"OKT",
    tokenName:'Rocket',
    swapLink:'https://www.cherryswap.net/#/swap',
    addLiquidityLink:'https://www.cherryswap.net/#/add',
  }
}

export const getChainName = (id) =>{
  return CHAINs[id] === undefined ? 'Network Error' : CHAINs[id].name;
}


export async function addNetwork(id) {

  let networkData;

  switch (id) {

    //bsctestnet

    case 66:

      networkData = [

        {

          chainId: '0x42',

          chainName: "OKExChain Mainnet",

          rpcUrls: ["https://exchainrpc.okex.org"],

          nativeCurrency: {

            name: "OKT",

            symbol: "OKT",

            decimals: 18,

          },

          blockExplorerUrls: ["https://www.oklink.com/okexchain"],

        },

      ];

      break;

    //bscmainet

    case 56:

      networkData = [

        {

          chainId: "0x38",

          chainName: "BSCMAINET",

          rpcUrls: ["https://bsc-dataseed1.binance.org"],

          nativeCurrency: {

            name: "BINANCE COIN",

            symbol: "ETH",

            decimals: 18,

          },

          blockExplorerUrls: ["https://testnet.bscscan.com/"],

        },

      ];

      break;


      case 42161:

      networkData = [

        {

          chainId: "0xA4B1",

          chainName: "Arbitrum One",

          rpcUrls: ["https://arb1.arbitrum.io/rpc"],

          nativeCurrency: {

            name: "ETH",

            symbol: "ETH",

            decimals: 18,

          },

          blockExplorerUrls: ["https://explorer.arbitrum.io"],

        },

      ];

      break;

    default:

      break;

  }

  // agregar red o cambiar red

  return window.ethereum.request({

    method: "wallet_addEthereumChain",

    params: networkData,

  }).catch(e=>{
    console.log(e)
  });

}
