import React, { Component } from "react";
import Routes from "./routes";
import Web3 from "web3";
import { notification } from "antd";
import { Utils } from "./utils/Utils";
import { addNetwork } from "./utils/chainsConfig";
import "./App.scss";

let timeoutTimer = null;
let defaultLang = "en"; //sessionStorage.getItem('lang') || 'en';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investor: "xxx",
      updating: false,
      logId: [],
      width: "100vw",
      height: "100vh",
      lang: defaultLang,
      chainId: undefined,
      address: null,
      languageFile: require("./assets/languages/" + defaultLang + ".json"),
    };
  }
  //read language selection from sessionStorage
  getLang() {
    // let browserLang = navigator.language || 'en';
    // if(!!browserLang) {
    // 	browserLang = browserLang.toLowerCase();
    // 	browserLang = browserLang.includes('zh') ? 'zh_CN' : 'en';
    //
    // }
    //
    //
    // var currentLang = sessionStorage.getItem('lang') || browserLang;
    // // currentLang = 'zh_CN';
    // sessionStorage.lang = currentLang;
    //
    // // console.log("called getInitailState");
    // this.setState({
    // 	lang: currentLang,
    // 	languageFile: require('./assets/languages/'+currentLang+'.json')
    // });
  }

  changLang = () => {
    if (this.state.lang === "en") {
      sessionStorage.lang = "zh_CN";
      this.setState({
        lang: "zh_CN",
        languageFile: require("./assets/languages/zh_CN.json"),
      });
    } else {
      sessionStorage.lang = "en";
      this.setState({
        lang: "en",
        languageFile: require("./assets/languages/en.json"),
      });
    }
  };

  componentDidMount = async () => {
    this.getLang();
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);

    // this.readWeb3Instance();

    // if (typeof window.ethereum !== 'undefined') {
    // 	window.ethereum.on('accountsChanged', (accounts)=>{
    // 		this.readWeb3Instance();
    // 	})
    // 	window.ethereum.on('chainChanged', (accounts)=>{
    // 		console.log("chainChanged");
    // 		window.location.replace('/');
    // 	})
    // }
    // else if (typeof window.web3 !== 'undefined') {
    // 	console.log(window.web3);
    // }
  };

  componentWillUnmount = () => {
    clearTimeout(timeoutTimer);
    window.removeEventListener("resize", this.updateDimensions);
  };

  updateDimensions = () => {
    let { innerWidth: width, innerHeight: height } = window;
    let ratio = width / height;
    let defaultRatio = 960 / 587;
    let transform = "";
    let udaptedWidth = 0;
    let updatedHight = 0;
    // mobile
    if (ratio < 1) {
      defaultRatio = 187 / 546;
      // transform = 'translate(-50%, -50%) rotate(90deg)';
      // let tempWidth = width;
      // width = height;
      // height = tempWidth;
      // ratio = width / height;
      // if(ratio < defaultRatio){
      // 	udaptedWidth = '100vh';
      // 	updatedHight = `${Math.floor(width / defaultRatio / height * 100)}vw`
      // }else{
      // 	udaptedWidth = `${Math.floor(defaultRatio * height / width * 100)}vh`;
      // 	updatedHight = `100vw`
      // }
    }
    // else{
    if (ratio < defaultRatio) {
      udaptedWidth = "100vw";
      updatedHight = `${Math.floor((width / defaultRatio / height) * 100)}vh`;
    } else {
      udaptedWidth = "100vw"; //`${Math.floor(defaultRatio * height / width * 100)}vw`
      updatedHight = "100vh";
    }
    // }

    this.setState({
      transform: transform,
      width: udaptedWidth,
      height: updatedHight,
    });
  };

  readWeb3Instance = () => {
    let newWeb3 = null;
    if (typeof window.ethereum !== "undefined") {
      window.ethereum.enable();
      newWeb3 = new Web3(window.ethereum);

      this.checkChainId(newWeb3);

      newWeb3.eth
        .getAccounts()
        .then((res) => {
          this.setState({
            address: res[0],
          });
        })
        .catch((err) => {
          console.log(err);
        });
      Utils.setWeb3(newWeb3);
    } else if (typeof window.web3 !== "undefined") {
      newWeb3 = new Web3(window.web3.currentProvider);
      this.checkChainId(newWeb3);
      if (newWeb3.eth.defaultAccount) {
        this.setState(
          {
            address: newWeb3.eth.defaultAccount,
          },
          () => {
            Utils.setWeb3(newWeb3);
          }
        );
      } else {
        this.checkChainId(newWeb3);
        newWeb3.eth
          .getAccounts()
          .then((res) => {
            this.setState({
              address: res[0],
            });
          })
          .catch((err) => {
            console.log(err);
          });
        Utils.setWeb3(newWeb3);
      }
    } else {
      console.error("wait for MetaMask");
      Utils.setWeb3(false);
      setTimeout(async () => {
        await this.readWeb3Instance();
      }, 1000);
    }
  };

  checkChainId = (web3) => {
    web3.eth.net
      .getId()
      .then((res) => {
        if (res !== 42161) {
          addNetwork(42161);
          this.setState({
            address: null,
          });
        } else {
          this.setState({
            chainId: 42161,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  openNotification = (message) => {
    const args = {
      message: <p className="investNotification">{message}</p>,
      description: "",
      duration: 4,
    };
    notification.open(args);
  };

  render() {
    return (
      <div
        className="App"
        style={{ width: this.state.width, height: this.state.height }}
      >
        <Routes
          lang={this.state.lang}
          address={this.state.address}
          languageFile={this.state.languageFile}
          changLang={this.changLang}
          size={{ width: this.state.width, height: this.state.height }}
        />
      </div>
    );
  }
}

export default App;
