import React, { Component } from 'react';
import {message, Tooltip} from 'antd';
import Countdown from '../../component/Countdown/Countdown';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {LoadingOutlined,QuestionCircleOutlined} from "@ant-design/icons";
import './MineInfo.scss';
const numeral = require('numeral');
class MineInfo extends Component {
  constructor(props){
    super(props);
    this.state={
      nextTime:"24:00:00",
      mySpeed: 2.5,
      frdSpeed:0,
      refCount:0,
      uid:88888
    }
  }


  render() {
    return (
        <div className="mineInfo">
          <h1>{this.props.languageFile.mineInfo.title}</h1>
          <p>
            {this.props.languageFile.mineInfo.yourTotalMiningRate}
            <span className="floatRight">
              {numeral(this.props.state.myRate).format('0')} DRAGON/{this.props.languageFile.home.hour}
            </span>
          </p>
          <p>
            {this.props.languageFile.mineInfo.yourBaseMiningRate}
            <span className="floatRight">
              {numeral(this.props.state.baseRate).format('0')} DRAGON/{this.props.languageFile.home.hour}
            </span>
          </p>

          <p>
            {this.props.languageFile.mineInfo.boostCountdown}
            <span className="floatRight">
              <Countdown date={this.props.state.boostTime}/>
            </span>
          </p>
          <div className="button" onClick={()=>{this.props.boost()}}>

            {
              this.props.boostLoading
                ?
                  <LoadingOutlined/>
                :
                this.props.languageFile.home.boost
            }
          </div>


          <br/>

          <p>
            {this.props.languageFile.mineInfo.referralBonusRate}
            <span className="floatRight">
              {numeral(this.props.state.bonusRate).format('0')} DRAGON/{this.props.languageFile.home.hour}
            </span>
          </p>
          <p style={{textAlign:"center"}}>
            {this.props.state.affCount} * 5% * {this.props.state.baseRate} = {numeral(this.props.state.bonusRate).format('0')} DRAGON/{this.props.languageFile.home.hour}
          </p>

          <p>
            {this.props.languageFile.mineInfo.yourReferrals}
            <span className="floatRight">
              {this.props.state.affCount}
            </span>
          </p>

          <p>
            {this.props.languageFile.mineInfo.bonusTimeRemaining} <Tooltip title={this.props.languageFile.home.referralRule}><QuestionCircleOutlined /></Tooltip>
            <span className="floatRight">
              <Countdown date={this.props.state.bonusTime}/>
            </span>
          </p>

          <br/>
          <p>{this.props.languageFile.mineInfo.inviteFriends}</p>

          <div className="buttonGroups">
            <div className="button">
              {this.props.state.uid}
            </div>
            <div className="button">
              <CopyToClipboard text={this.props.state.uid}
                onCopy={() => { message.info(this.props.languageFile.message.successCopy)}}>
                <span>{this.props.languageFile.mineInfo.copyCode}</span>
              </CopyToClipboard>
            </div>
          </div>


        </div>


    );
  }
}

export default MineInfo;
