import React, { Component } from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
import {
  Utils,
  BabyFarm,
  BabyStaking,
  multiCall,
  date2CountdownString,
  LAUNCH_TIME,
  YEAR,
} from "./utils/Utils";
import Home from "./views/Home/Home";

class Routes extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      mainTokenPrice: 0,
      nativePrice: 0,
      refCode: "",
      uid: 0,
      baseRate: 2000000,
      rate: 2000000,
      referralBonusRate: 0,
      affCount: 0,
      nativeBalance: 0,
      profitPaused: true,
      activeFee: 0.01 * 1000000000000000000,
      transactionFee: 0.05 * 1000000000000000000,
      startEarned: 0,
      earned: 0,
      nextLevel: 1000,
      startTime: LAUNCH_TIME,
      boostTime: "Aug 7 2020 12:00:00 UTC",
      bonusTime: "Aug 7 2020 12:00:00 UTC",

      dividendsRound: 1,
      dividendsRate: 5,
      divCountdown: "Aug 15 2020 12:00:00 UTC",
      pendingCountdown: "Aug 15 2020 12:00:00 UTC",
      stakingStarttime: "Aug 15 2020 12:00:00 UTC",
      stakingAllowance: 0,

      dragonBalance: 0,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.checkContract();
    this.timer = setInterval(() => {
      this.checkContract();
    }, 1000);
  };

  componentDidUpdate = (prevPros) => {
    if (prevPros.address !== this.props.address) {
      this.checkContract();
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  checkContract = () => {
    if (Utils.web3 && this.props.address && Utils.babyFarm) {
      clearInterval(this.timer);
      this.getFarmStats();
    }
  };

  getNativeBalance = () => {
    Utils.web3.eth
      .getBalance(this.props.address)
      .then((res) => {
        this.setState({
          nativeBalance: Utils.web3.utils.fromWei(res),
        });
      })
      .catch(console.log);

    Utils.dragon.methods
      .balanceOf(this.props.address)
      .call()
      .then((res) => {
        this.setState({
          dragonBalance: Utils.web3.utils.fromWei(res),
        });
      })
      .catch(console.log);
  };

  getMainTokenPrice = async () => {
    // calc rocket price
    if (Utils.mainToken) {
      const native1stableCoinPair = await Utils.swapFactory.methods
        .getPair(Utils.wrappedNative._address, Utils.stableCoin._address)
        .call();
      const stableCoinBalanceAtNative1StableCoinPair =
        await Utils.stableCoin.methods.balanceOf(native1stableCoinPair).call();
      const nativeBalanceAtBusdPair = await Utils.wrappedNative.methods
        .balanceOf(native1stableCoinPair)
        .call();
      const nativePrice =
        parseInt(stableCoinBalanceAtNative1StableCoinPair) /
        parseInt(nativeBalanceAtBusdPair);

      const mainToken1WrapperNativePair = await Utils.swapFactory.methods
        .getPair(Utils.mainToken._address, Utils.wrappedNative._address)
        .call();
      const warppedNativeBalanceAtPair = await Utils.wrappedNative.methods
        .balanceOf(mainToken1WrapperNativePair)
        .call();
      const mainTokenBalanceAtPair = await Utils.mainToken.methods
        .balanceOf(mainToken1WrapperNativePair)
        .call();
      let mainTokenPrice =
        (parseInt(warppedNativeBalanceAtPair) /
          parseInt(mainTokenBalanceAtPair)) *
        nativePrice;

      if (isNaN(mainTokenPrice)) {
        mainTokenPrice = 0;
      }
      this.setState({
        nativePrice: nativePrice,
        mainTokenPrice: mainTokenPrice,
      });
    }
  };

  getStakingData = async () => {
    const myTokenBalance = Utils.web3.utils.fromWei(
      await Utils.mainToken.methods.balanceOf(this.props.address).call()
    );
    const stakingAllowance = Utils.web3.utils.fromWei(
      await Utils.mainToken.methods
        .allowance(this.props.address, Utils.babyStaking._address)
        .call()
    );

    const calls = [];

    calls.push([Utils.babyStaking._address, "roundId", []]);
    calls.push([Utils.babyStaking._address, "dividendsPeriod", []]);
    calls.push([Utils.babyStaking._address, "dividendsRate", []]);
    calls.push([Utils.babyStaking._address, "totalSupply", []]);
    calls.push([Utils.babyStaking._address, "fundToBeDividend", []]);
    calls.push([Utils.babyStaking._address, "starttime", []]);
    calls.push([Utils.babyStaking._address, "withdrawCoolDown", []]);
    calls.push([Utils.babyStaking._address, "lastUpdateTime", []]);

    calls.push([Utils.babyStaking._address, "balanceOf", [this.props.address]]);
    calls.push([Utils.babyStaking._address, "pendingOf", [this.props.address]]);
    calls.push([
      Utils.babyStaking._address,
      "withdrawCoolDownMap",
      [this.props.address],
    ]);
    calls.push([Utils.babyStaking._address, "earned", [this.props.address]]);
    calls.push([
      Utils.babyStaking._address,
      "currentRoundRewardProjection",
      [this.props.address],
    ]);

    calls.push([Utils.babyStaking._address, "nextReward", []]);

    const res = await multiCall(BabyStaking.abi, calls);
    const currTime = new Date().getTime();

    const roundId = res[0][0].toString();
    const dividendsPeriod = res[1][0].toString();
    const dividendsRate = res[2][0].toString();
    const totalSupply = Utils.web3.utils.fromWei(res[3][0].toString());
    const totalFundAmount = Utils.web3.utils.fromWei(res[4][0].toString());
    const starttime = res[5][0].toString();
    const withdrawCoolDown = res[6][0].toString();
    const lastDistributionTime = res[7][0].toString();
    const staked = Utils.web3.utils.fromWei(res[8][0].toString());
    let pending = Utils.web3.utils.fromWei(res[9][0].toString());
    const pendingCountdown = res[10][0].toString();
    const earned = Utils.web3.utils.fromWei(res[11][0].toString());
    const yourEstimate = Utils.web3.utils.fromWei(res[12][0].toString());
    const curDivAmount = (totalFundAmount * dividendsRate) / 100;
    let apy = 0;
    if (parseFloat(totalSupply) > 0) {
      apy =
        (((YEAR / dividendsPeriod) * this.state.nativePrice * curDivAmount) /
          (totalSupply * this.state.mainTokenPrice)) *
        100;
    }
    let unstaked = 0;
    if (
      (parseInt(pendingCountdown) + parseInt(withdrawCoolDown)) * 1000 <
      currTime
    ) {
      unstaked = pending;
      pending = 0;
    }

    this.setState({
      totalFundAmount: totalFundAmount,
      curDivAmount: curDivAmount,
      dividendsRound: roundId,
      dividendsRate: dividendsRate,
      stakingStarttime: date2CountdownString(
        new Date(parseInt(starttime) * 1000)
      ),
      divCountdown: date2CountdownString(
        new Date(
          (parseInt(lastDistributionTime) + parseInt(dividendsPeriod)) * 1000
        )
      ),
      pendingCountdown: date2CountdownString(
        new Date(
          (parseInt(pendingCountdown) + parseInt(withdrawCoolDown)) * 1000
        )
      ),
      totalStaked: totalSupply,
      yourEstimate: yourEstimate,
      myTokenBalance: myTokenBalance,
      stakingAllowance: stakingAllowance,
      stakingApy: apy,
      staked: staked,
      stakingEarned: earned,
      pending: pending,
      unstaked: unstaked,
    });
  };

  getFarmStats = async () => {
    clearInterval(this.getPoolStatsTimer);
    this.getNativeBalance();
    // this.getMainTokenPrice();
    //
    // if(this.state.mainTokenPrice === 0){
    //   this.readPriceTimer = setTimeout(()=>{
    //     this.getFarmStats();
    //   }, 1000);
    //   return;
    // }
    // this.getStakingData();
    const calls = [];
    calls.push([Utils.babyFarm._address, "totalMiners", []]);
    calls.push([
      Utils.babyFarm._address,
      "address2miner",
      [this.props.address],
    ]);
    calls.push([
      Utils.babyFarm._address,
      "getTotalRewards",
      [this.props.address],
    ]);
    calls.push([Utils.babyFarm._address, "baseRate", []]);
    calls.push([Utils.babyFarm._address, "getMyRate", [this.props.address]]);
    calls.push([Utils.babyFarm._address, "startTime", []]);
    calls.push([Utils.babyFarm._address, "getHalfThreshold", []]);

    // calls.push([Utils.babyFarm._address, 'REF_CODE', []]);

    const currTime = new Date().getTime();
    const res = await multiCall(BabyFarm.abi, calls);
    if (!res) {
      return;
    }
    let totalMiners = res[0][0].toString();

    const playerData = res[1];
    const earned = Utils.web3.utils.fromWei(res[2][0].toString());
    const baseRate = Utils.web3.utils.fromWei(res[3][0].toString());
    let myRate = 0;

    const profitPaused =
      currTime / 1000 > parseInt(playerData.boostTime.toString());
    const bonusPaused =
      currTime / 1000 > parseInt(playerData.bonusTime.toString());

    if (!profitPaused) {
      myRate = parseFloat(baseRate);
    }
    if (!bonusPaused) {
      myRate = parseFloat(Utils.web3.utils.fromWei(res[4][0].toString()));
    }
    const bonusRate = myRate - parseFloat(baseRate);
    // const totalMiners = await Utils.babyFarm.methods.totalMiners().call();
    // const playerData = await Utils.babyFarm.methods.address2miner(this.props.address).call();
    // const profit = Utils.web3.utils.fromWei(await Utils.babyFarm.methods.getTotalRewards(this.props.address).call());
    // if(!checkTime()){
    //   totalMiners = 0;
    // }
    this.setState(
      {
        totalMiners: totalMiners,
        earned: earned,
        baseRate: baseRate * 3600,
        myRate: myRate * 3600,
        bonusRate: bonusRate * 3600,
        referralBonusRate: myRate * 3600 - baseRate * 3600,
        uid: parseInt(playerData.uid.toString()),
        affCount: playerData.affCount.toString(),
        profitPaused: profitPaused,
        startTime: date2CountdownString(
          new Date(parseInt(res[5][0].toString()) * 1000)
        ),
        boostTime: date2CountdownString(
          new Date(parseInt(playerData.boostTime.toString()) * 1000)
        ),
        bonusTime: date2CountdownString(
          new Date(parseInt(playerData.bonusTime.toString()) * 1000)
        ),
        nextLevel: parseInt(res[6][0].toString()),
      },
      () => {
        setTimeout(() => {
          this.setState({
            startEarned: this.state.earned,
          });
        }, 7500);
      }
    );
    this.getPoolStatsTimer = setTimeout(() => {
      this.getFarmStats();
    }, 8000);
  };

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Home
                state={this.state}
                address={this.props.address}
                size={this.props.size}
                lang={this.props.lang}
                changLang={this.props.changLang}
                languageFile={this.props.languageFile}
              />
            )}
          />
          {/*<Route exact path="/staking" render={()=>(*/}
          {/*  <Staking*/}
          {/*    state={this.state}*/}
          {/*    address={this.props.address}*/}
          {/*    size={this.props.size}*/}
          {/*    lang={this.props.lang}*/}
          {/*    changLang={this.props.changLang}*/}
          {/*    getStakingData={this.getStakingData}*/}
          {/*    languageFile = {this.props.languageFile}*/}
          {/*  />)}*/}
          {/*/>*/}
          <Route
            render={() => (
              <Home
                state={this.state}
                address={this.props.address}
                size={this.props.size}
                lang={this.props.lang}
                changLang={this.props.changLang}
                languageFile={this.props.languageFile}
              />
            )}
          />
        </Switch>
      </HashRouter>
    );
  }
}

export default Routes;
