import React, { Component } from "react";
import { Modal, message } from "antd";
import { Utils, numeral, checkTime, reduceAddress } from "../../utils/Utils";
// import CountUp from "react-countup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Countdown from "../../component/Countdown/Countdown";
import MineInfo from "./MineInfo";
// import { LoadingOutlined } from "@ant-design/icons";
import "./Home.scss";
import styles from "./Home.module.scss";
import classNames from "classnames";

const contractAddress = "";
class Home extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      refCode: "",
      uid: 88888,
      baseRate: 2000000,
      rate: 2000000,
      referralBonusRate: 0,
      affCount: 0,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  handleClick() {
    const url = "https://verfication.nodedragons.com";
    window.open(url, "_blank", "noopener,noreferrer");
  }

  render() {
    return (
      <div className="home">
        <div className={styles.header}>
          <div />
          <img src={require("../../assets/images/textLogo.svg")} alt="" />
          <div className="langToggle" onClick={this.props.changLang}>
            <span className={this.props.lang === "en" ? "selected" : ""}>
              EN
            </span>
            &nbsp;/&nbsp;
            <span className={this.props.lang === "zh_CN" ? "selected" : ""}>
              CN
            </span>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.globalInfo1}>
            <div className={classNames(styles.card, styles.infoCard)}>
              <h2>{this.props.languageFile.home.totalMiner}</h2>
              <p>{numeral(this.props.state.totalMiners).format("0,0")}</p>
            </div>
            <div className={classNames(styles.card, styles.infoCard)}>
              <h2>{this.props.languageFile.home.contract}</h2>
              <p>{contractAddress ? reduceAddress(contractAddress) : "-"}</p>
              <CopyToClipboard
                text={contractAddress}
                onCopy={() => {
                  message.info(this.props.languageFile.message.successCopy);
                }}
              >
                <div className={styles.copyButton}>Copy</div>
              </CopyToClipboard>
            </div>
          </div>
          <div className="mineWrap">
            <img
              className="dragonIslandTextLogo"
              src={require("../../assets/images/dragonIsland.svg")}
              alt="dragon island"
            />

            <div className={styles.countdownWrap}>
              <h2>{this.props.languageFile.home.startCountdown}</h2>
              <h2>
                <Countdown date={this.props.state.startTime} />
              </h2>
            </div>
            <div className={styles.buttonWrapper}>
              <div className="button">
                <a
                  href="https://inscribenow.io/collections/02a1a6722eecc0d5"
                  target={"_blank"}
                  rel={"noopener,noreferrer"}
                >
                  {this.props.languageFile.home.Mint}
                </a>
              </div>
              <div className="button" onClick={this.handleClick}>
                {this.props.languageFile.home.CheckOg}
              </div>
            </div>
            {/* <div className="contentWrap" align="middle">
              <h3>{this.props.languageFile.home.miningCenterTitle}</h3>

              {checkTime(this.props.state.startTime) ? (
                <div>
                  <h2>
                    <CountUp
                      start={this.props.state.startEarned}
                      end={this.props.state.earned}
                      duration={7}
                      separator={","}
                      decimals={0}
                      decimal="."
                      suffix=" DRAGON"
                      useEasing={false}
                    />
                  </h2>
                  <h2>
                    {numeral(this.props.state.myRate).format("0,0.[0000]")}{" "}
                    DRAGON / {this.props.languageFile.home.hour}
                  </h2>
                </div>
              ) : (
                <div className="countdownWrap">
                  <h2>{this.props.languageFile.home.startCountdown}</h2>
                  <h2>
                    <Countdown date={this.props.state.startTime} />
                  </h2>
                </div>
              )}

              {this.props.state.uid === 0 ? (
                checkTime(this.props.state.startTime) ? (
                  <div className={"buttonGroup"}>
                    <div
                      className="button"
                      onClick={() => {
                        // this.setState({ refCodeVisible: true });
                        message.info("Coming Soon");
                      }}
                    >
                      {this.state.activateLoading ? (
                        <LoadingOutlined />
                      ) : (
                        this.props.languageFile.home.startMining
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className="button"
                    onClick={() => {
                      message.info(this.props.languageFile.message.comingSoon);
                    }}
                  >
                    {this.props.languageFile.home.startMining}
                  </div>
                )
              ) : (
                <div className="buttonGroup">
                  {this.props.state.profitPaused ? (
                    <div
                      className="button buttonDisabled"
                      onClick={() => {
                        this.boost();
                      }}
                    >
                      {this.state.boostLoading ? (
                        <LoadingOutlined />
                      ) : (
                        this.props.languageFile.home.boost
                      )}
                    </div>
                  ) : (
                    <div
                      className="button"
                      onClick={() => {
                        this.setState({ mineDetailVisible: true });
                      }}
                    >
                      {this.state.boostLoading ? (
                        <LoadingOutlined />
                      ) : (
                        this.props.languageFile.home.boost
                      )}
                    </div>
                  )}
                  <div
                    className="button"
                    onClick={() => {
                      this.withdraw();
                    }}
                  >
                    {this.state.withdrawLoading ? (
                      <LoadingOutlined />
                    ) : (
                      this.props.languageFile.home.withdraw
                    )}
                  </div>
                </div>
              )}
              {/* <div className={"buttonGroup"}>
                <a
                  className="button minNFT"
                  href={"https://stake.dragoncity.games/#/"}
                  target={"_blank"}
                >
                  DRAGON Stake
                </a>
                <a
                  className="button minNFT"
                  href={"https://stake.dragoncity.games/#/eth"}
                  target={"_blank"}
                >
                  ETH Stake
                </a>
              </div> 

              {checkTime() ? (
                <div className="boostCountdownWrap">
                  {this.props.languageFile.message.comingSoon}
                  {/* {this.props.languageFile.home.boostCountdown}&nbsp;
                  <Countdown date={this.props.state.boostTime} /> 
                </div>
              ) : null}

              <p>{this.props.languageFile.home.boostTip}</p>
            </div> */}
          </div>
          {/* <div className={classNames(styles.card, styles.miningCenter)}>

          </div> */}

          <div className={styles.globalInfo2}>
            <div className={classNames(styles.card, styles.infoCard)}>
              <h2>{this.props.languageFile.home.currentBaseRate}</h2>
              <p>{numeral(this.props.state.baseRate).format("0,0")}</p>
              <p>Dragon / {this.props.languageFile.home.hour}</p>
            </div>
            <div className={classNames(styles.card, styles.infoCard)}>
              <h2>{this.props.languageFile.home.halfCountdown}</h2>
              <p>
                {numeral(this.props.state.totalMiners).format("0,0")} /{" "}
                {numeral(this.props.state.nextLevel).format("0,0")}{" "}
                {this.props.languageFile.home.miners}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.rules}>
            <p onClick={this.toggleMiningRule}>
              {this.props.languageFile.home.miningRuleTitle}
            </p>

            <p onClick={this.toggleReferralRule}>
              {this.props.languageFile.home.referralTitle}
            </p>
            <p>
              {this.props.languageFile.home.invited}:{" "}
              {numeral(this.props.state.affCount).format("0,0")}
            </p>
          </div>
          <div className={styles.centerWrap}>
            {this.props.languageFile.home.miningReferralTitle}
          </div>
          <div className={styles.linkWrap}>
            {/* <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://t.me/NodeDragons"
            >
              <img
                draggable="false"
                src={require("../../assets/images/telegram.png")}
                alt="telegram"
              />
            </a> */}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/NodeDragons"
            >
              <img
                draggable="false"
                src={require("../../assets/images/twitter.png")}
                alt="twitter"
              />
            </a>
            {/* <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://discord.com/invite/tFe7HrrA5W"
            >
              <img
                draggable="false"
                src={require("../../assets/images/discord.svg")}
                alt="discord"
              />
            </a> */}
            {/* <a target="_blank" rel="noopener noreferrer">
              <img
                draggable="false"
                src={require("../../assets/images/arbiscan.png")}
                alt="bscscan"
              />
            </a>
            <a target="_blank" rel="noopener noreferrer">
              <img
                draggable="false"
                src={require("../../assets/images/gitbook.png")}
                alt="gitbook"
              />
            </a>
            <a target="_blank" rel="noopener noreferrer">
              <img
                draggable="false"
                src={require("../../assets/images/eagle.png")}
                alt="eagle"
              />
            </a> */}
          </div>
        </div>

        {/* <div className="totalMinerWrap">
          <div className="contentWrap" align="left">
            <h2>{this.props.languageFile.home.totalMiner}</h2>
            <p>{numeral(this.props.state.totalMiners).format("0,0")}</p>
          </div>
        </div>
        <img
          draggable="false"
          className="textLogo"
          src={require("../../assets/images/textLogo.png")}
          alt="textLogo"
        />
        <div className="walletBalanceWrap">
          <div className="contentWrap" align="left">
            {reduceAddress(this.props.address)}
          </div>
          <div className="balanceWrap" align="left">
            <p>
              {numeral(this.props.state.dragonBalance).format("0,0")} DRAGON
            </p>
            <a
              href={
                "https://app.uniswap.org/#/tokens/arbitrum/0xf0f942d563a6bacf875d8cee5ae663b12ce62149"
              }
              target={"_blank"}
              rel={"noreferrer"}
            >
              BUY NOW
            </a>
          </div>
        </div>
        <div draggable="false" className="halfWrap">
          <div className="infoItem">
            <h2>{this.props.languageFile.home.currentBaseRate}</h2>
            <h2>
              {numeral(this.props.state.baseRate).format("0,0")} DRAGON/
              {this.props.languageFile.home.hour}
            </h2>
          </div>

          <div className="infoItem">
            <h2>{this.props.languageFile.home.halfCountdown}</h2>
            <h2>
              {numeral(this.props.state.totalMiners).format("0,0")} /{" "}
              {numeral(this.props.state.nextLevel).format("0,0")}{" "}
              {this.props.languageFile.home.miners}
            </h2>
          </div>
        </div>

        <div className="socialMediaWrap">
          <a
            href="https://t.me/DragonCityEN"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              draggable="false"
              src={require("../../assets/images/telegram.png")}
              alt="telegram"
            />
          </a>
          <a
            href="https://twitter.com/DragonCityNET"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              draggable="false"
              src={require("../../assets/images/twitter.png")}
              alt="twitter"
            />
          </a>
          <a
            href={
              "https://arbiscan.io/token/0xF0f942D563A6BaCf875d8cEe5AE663b12Ce62149"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              draggable="false"
              src={require("../../assets/images/arbiscan.png")}
              alt="bscscan"
            />
          </a>
          <a
            href={"https://whitepaper-20.gitbook.io/dragon-city-network/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              draggable="false"
              src={require("../../assets/images/gitbook.png")}
              alt="gitbook"
            />
          </a>
          <a
            href={
              "https://dexscreener.com/arbitrum/0x10d75ea220a3cce7b537d3dd83b8b01c46e1b847"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              draggable="false"
              src={require("../../assets/images/eagle.png")}
              alt="eagle"
            />
          </a>
        </div>


        <div className="mineWrap">

          <div className="contentWrap" align="middle">
            <h3>{this.props.languageFile.home.miningTitle}</h3>

            {checkTime(this.props.state.startTime) ? (
              <div>
                <h1>
                  <CountUp
                    start={this.props.state.startEarned}
                    end={this.props.state.earned}
                    duration={7}
                    separator={","}
                    decimals={0}
                    decimal="."
                    suffix=" DRAGON"
                    useEasing={false}
                  />
                </h1>
                <h2>
                  {numeral(this.props.state.myRate).format("0,0.[0000]")}{" "}
                  DRAGON/
                  {this.props.languageFile.home.hour}
                </h2>
              </div>
            ) : (
              <div className="countdownWrap">
                <h2>{this.props.languageFile.home.startCountdown}</h2>
                <h2>
                  <Countdown date={this.props.state.startTime} />
                </h2>
              </div>
            )}

            {this.props.state.uid === 0 ? (
              checkTime(this.props.state.startTime) ? (
                <div className={"buttonGroup"}>
                  <div
                    className="button"
                    onClick={() => {
                      this.setState({ refCodeVisible: true });
                    }}
                  >
                    {this.state.activateLoading ? (
                      <LoadingOutlined />
                    ) : (
                      this.props.languageFile.home.startMining
                    )}
                  </div>
                </div>
              ) : (
                <div className="button">
                  {this.props.languageFile.home.startMining}
                </div>
              )
            ) : (
              <div className="buttonGroup">
                {this.props.state.profitPaused ? (
                  <div
                    className="button buttonDisabled"
                    onClick={() => {
                      this.boost();
                    }}
                  >
                    {this.state.boostLoading ? (
                      <LoadingOutlined />
                    ) : (
                      this.props.languageFile.home.boost
                    )}
                  </div>
                ) : (
                  <div
                    className="button"
                    onClick={() => {
                      this.setState({ mineDetailVisible: true });
                    }}
                  >
                    {this.state.boostLoading ? (
                      <LoadingOutlined />
                    ) : (
                      this.props.languageFile.home.boost
                    )}
                  </div>
                )}
                <div
                  className="button"
                  onClick={() => {
                    this.withdraw();
                  }}
                >
                  {this.state.withdrawLoading ? (
                    <LoadingOutlined />
                  ) : (
                    this.props.languageFile.home.withdraw
                  )}
                </div>
              </div>
            )}
            <div className={"buttonGroup"}>
              <a
                className="button minNFT"
                href={"https://stake.dragoncity.games/#/"}
                target={"_blank"}
              >
                DRAGON Stake
              </a>
              <a
                className="button minNFT"
                href={"https://stake.dragoncity.games/#/eth"}
                target={"_blank"}
              >
                ETH Stake
              </a>
            </div>

            {checkTime() ? (
              <div className="boostCountdownWrap">
                {this.props.languageFile.home.boostCountdown}&nbsp;
                <Countdown date={this.props.state.boostTime} />
              </div>
            ) : null}

            <p>{this.props.languageFile.home.boostTip}</p>
          </div>
        </div>

        <div className="referralWarp">
          <div className="contentWrap" align="left">
            {this.props.state.uid === 0 ? (
              <div>
                <h2>{this.props.languageFile.home.startMining}</h2>
                <h2>{this.props.languageFile.home.generateCode}</h2>
              </div>
            ) : (
              <div>
                <h2>
                  {this.props.languageFile.home.refCode}: {this.props.state.uid}
                </h2>
                <div className="button">
                  <CopyToClipboard
                    text={this.props.state.uid}
                    onCopy={() => {
                      message.info(this.props.languageFile.message.successCopy);
                    }}
                  >
                    <span>{this.props.languageFile.mineInfo.copyCode}</span>
                  </CopyToClipboard>
                </div>
              </div>
            )}

            <p>
              {this.props.languageFile.home.invited}:{" "}
              {numeral(this.props.state.affCount).format("0,0")}
            </p>
          </div>
        </div>

        <div className="ruleWrap">
          <div className="rules" onClick={this.toggleMiningRule}>
            {this.props.languageFile.home.miningRuleTitle}
          </div>

          <div className="rules" onClick={this.toggleReferralRule}>
            {this.props.languageFile.home.referralTitle}
          </div>
        </div> */}

        <Modal
          visible={this.state.refCodeVisible}
          onOk={this.handleOk}
          centered={true}
          onCancel={() => {
            this.setState({ refCodeVisible: false });
          }}
          footer={null}
        >
          <div align="middle">
            {/*<p>{this.props.languageFile.message.activeFeeNote}</p>*/}
            <div>
              <p>
                {this.props.languageFile.home.refCode}
                <input
                  className="inputBuy"
                  type="text"
                  value={this.state.refCode}
                  onChange={(e) => {
                    this.handleRefCodeChange(e.target.value);
                  }}
                />
              </p>
              {this.state.showErrorMessage ? (
                <p>{this.props.languageFile.home.invalidRef}</p>
              ) : null}

              <div className="buttonGroups">
                <div
                  className="button"
                  onClick={() => {
                    this.setState({ refCodeVisible: false });
                  }}
                >
                  {this.props.languageFile.message.cancel}
                </div>
                <div
                  className="button"
                  onClick={() => {
                    this.handleOk();
                  }}
                >
                  {this.props.languageFile.message.confirm}
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          visible={this.state.miningRuleVisible}
          onOk={this.toggleMiningRule}
          centered={true}
          onCancel={this.toggleMiningRule}
          footer={null}
          wrapClassName={"modalWrap"}
        >
          <div className="miningRuleModalWrap">
            <h2>{this.props.languageFile.home.miningTitle}</h2>
            <p>{this.props.languageFile.home.miningRule}</p>
            {/*<br />*/}
            {/*<h2>{this.props.languageFile.home.tokenomics}</h2>*/}
            {/*<p>{this.props.languageFile.home.tokenomicsInfo}</p>*/}
          </div>
        </Modal>

        <Modal
          visible={this.state.referralRuleVisible}
          onOk={this.toggleReferralRule}
          centered={true}
          onCancel={this.toggleReferralRule}
          footer={null}
          wrapClassName={"modalWrap"}
        >
          <div className="referralRuleModalWrap">
            <h2>{this.props.languageFile.home.referralTitle}</h2>
            <p>{this.props.languageFile.home.referralRule}</p>
          </div>
        </Modal>

        <Modal
          visible={this.state.mineDetailVisible}
          onOk={this.toggleMineDetail}
          centered={true}
          onCancel={this.toggleMineDetail}
          footer={null}
          wrapClassName={"modalWrap"}
        >
          <MineInfo
            state={this.props.state}
            boost={this.boost}
            boostLoading={this.state.boostLoading}
            languageFile={this.props.languageFile}
          />
        </Modal>
        {/*
        <Modal
          visible={this.state.contractInfoVisible}
          onOk={this.toggleContractInfo}
          centered={true}
          onCancel={this.toggleContractInfo}
          footer={null}
          wrapClassName={"modalWrap"}
        >
          {checkTime(PANCAKE_LAUNCH_TIME) ? (
            <div className="pancakeCountdownWrap">
              <CopyToClipboard
                text={Utils.tokenAddress}
                onCopy={() => {
                  message.info(this.props.languageFile.message.successCopy, 1);
                }}
              >
                <h2>
                  {this.props.languageFile.home.contract}: {Utils.tokenAddress}{" "}
                  <CopyOutlined />
                </h2>
              </CopyToClipboard>
              <div className="buttonGroups">
                <a
                  href={`https://pancakeswap.finance/swap?inputCurrency=ETH&outputCurrency=${Utils.tokenAddress}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../../assets/images/cake.png")}
                    alt="trade"
                  />
                  {this.props.languageFile.home.trade}
                </a>
                <a
                  href="https://www.dextools.io/app/pancakeswap/pair-explorer/0x915c6853a7edb119b211e4a613c3337559f74439"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../../assets/images/dext.png")}
                    alt="dext"
                  />
                  {this.props.languageFile.home.chart}
                </a>
              </div>
            </div>
          ) : (
            <div className="pancakeCountdownWrap">
              <h2>
                {this.props.languageFile.home.swapCountdown}{" "}
                <Countdown date={PANCAKE_LAUNCH_TIME} />
              </h2>
              <CopyToClipboard
                text={Utils.tokenAddress}
                onCopy={() => {
                  message.info(this.props.languageFile.message.successCopy, 1);
                }}
              >
                <h2>
                  {this.props.languageFile.home.contract}: {Utils.tokenAddress}{" "}
                  <CopyOutlined />
                </h2>
              </CopyToClipboard>
            </div>
          )}
        </Modal> */}
      </div>
    );
  }

  handleRefCodeChange = (value) => {
    if (!isNaN(value)) {
      this.setState({
        refCode: value,
      });
    }
  };

  toggleMiningRule = () => {
    this.setState({
      miningRuleVisible: !this.state.miningRuleVisible,
    });
  };

  toggleReferralRule = () => {
    this.setState({
      referralRuleVisible: !this.state.referralRuleVisible,
    });
  };

  toggleMineDetail = () => {
    this.setState({
      mineDetailVisible: !this.state.mineDetailVisible,
    });
  };

  handleOk = () => {
    if (this.props.state.uid === 0) {
      Utils.babyFarm.methods
        .validateReferrer(this.state.refCode)
        .call()
        .then((res) => {
          if (res) {
            this.activate();
            this.setState({
              refCodeVisible: false,
              showErrorMessage: false,
            });
          } else {
            this.setState({
              showErrorMessage: true,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  checkGas = () => {
    if (this.props.state.nativeBalance < 0.0001) {
      message.info(this.props.languageFile.message.insufficientGas, 3);
      return false;
    }
    return true;
  };

  withdraw = () => {
    if (!checkTime(this.props.state.startTime) || this.state.withdrawLoading) {
      return;
    }

    if (!this.checkGas()) {
      return;
    }
    if (!this._isMounted) {
      return;
    }
    this.setState({
      withdrawLoading: true,
    });

    if (Utils.babyFarm) {
      Utils.babyFarm.methods
        .withdraw()
        .send({
          from: this.props.address,
          gasPrice: 200000000,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          if (!this._isMounted) {
            return;
          }
          this.setState({
            withdrawLoading: true,
          });
          message.success(this.props.languageFile.message.transactionSent, 3);
        })
        .then((res) => {
          if (!this._isMounted) {
            return;
          }
          this.setState({
            withdrawLoading: false,
          });
          message.success(
            this.props.languageFile.message.transactionSuccess,
            3
          );
        })
        .catch((err) => {
          console.log(err);
          if (!this._isMounted) {
            return;
          }
          this.setState({
            withdrawLoading: false,
          });
        });
    } else {
      if (!this._isMounted) {
        return;
      }
      this.setState({
        withdrawLoading: false,
      });
    }
  };

  activate = () => {
    if (!checkTime(this.props.state.startTime) || this.state.activateLoading) {
      return;
    }

    if (!this.checkGas()) {
      return;
    }

    if (!this._isMounted) {
      return;
    }
    this.setState({
      activateLoading: true,
    });

    if (Utils.babyFarm) {
      Utils.babyFarm.methods
        .activate(this.state.refCode)
        .send({
          from: this.props.address,
          gasPrice: 200000000,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          if (!this._isMounted) {
            return;
          }
          this.setState({
            activateLoading: true,
          });
          message.success(this.props.languageFile.message.transactionSent, 3);
        })
        .then((res) => {
          if (!this._isMounted) {
            return;
          }
          this.setState({
            activateLoading: false,
          });
          message.success(
            this.props.languageFile.message.transactionSuccess,
            3
          );
        })
        .catch((err) => {
          console.log(err);
          if (!this._isMounted) {
            return;
          }
          this.setState({
            activateLoading: false,
          });
        });
    } else {
      if (!this._isMounted) {
        return;
      }
      this.setState({
        activateLoading: false,
      });
    }
  };

  boost = () => {
    if (!checkTime(this.props.state.startTime) || this.state.boostLoading) {
      return;
    }

    if (!this.checkGas()) {
      return;
    }
    if (!this._isMounted) {
      return;
    }
    this.setState({
      boostLoading: true,
    });

    if (Utils.babyFarm) {
      Utils.babyFarm.methods
        .boost()
        .send({
          from: this.props.address,
          gasPrice: 200000000,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          if (!this._isMounted) {
            return;
          }
          this.setState({
            boostLoading: true,
          });
          message.success(this.props.languageFile.message.transactionSent, 3);
        })
        .then((res) => {
          if (!this._isMounted) {
            return;
          }
          this.setState({
            boostLoading: false,
          });
          message.success(
            this.props.languageFile.message.transactionSuccess,
            3
          );
        })
        .catch((err) => {
          console.log(err);
          if (!this._isMounted) {
            return;
          }
          this.setState({
            boostLoading: false,
          });
        });
    } else {
      if (!this._isMounted) {
        return;
      }
      this.setState({
        boostLoading: false,
      });
    }
  };
}

export default Home;
