// Create instance of contract object
import { Interface } from "@ethersproject/abi";
export const SwapRouter = require("./contracts/SwapRouter.json");
export const SwapFactory = require("./contracts/SwapFactory.json");
export const IERC20 = require("./contracts/IERC20.json");
export const BabyFarm = require("./contracts/BabyFarm.json");
export const BabyStaking = require("./contracts/BabyStaking.json");
export const MultiCall = require("./contracts/MultiCall.json");
export const babyMiner = "0xB8328dE7A7225F56d5e1cEB55AAA7965d403b2af"; //"0xB8328dE7A7225F56d5e1cEB55AAA7965d403b2af";
export const ADDRESSES = require("./ADDRESSES.json");
export const Utils = {
  web3: false,
  tokenAddress: babyMiner,

  ownerAddr: "0x1E9005bFB450e8c433aA997a23f3D2069106f8a7",
  async setWeb3(web3) {
    this.web3 = web3;

    // const tokens = Object.keys(ADDRESSES.tokens);
    // for(let i = 0; i < tokens.length; i++){
    //   if(web3.utils.isAddress(ADDRESSES.tokens[tokens[i]])){
    //     this[tokens[i]] = new web3.eth.Contract(IERC20.abi, ADDRESSES.tokens[tokens[i]]);
    //   }
    // }

    this.babyFarm = new web3.eth.Contract(BabyFarm.abi, ADDRESSES.babyFarm);
    this.dragon = new web3.eth.Contract(IERC20.abi, ADDRESSES.dragon);
    this.multiCall = new web3.eth.Contract(MultiCall, ADDRESSES.multiCall);
  },
};

export const SECOND = 1;
export const MINUTES = SECOND * 60;
export const HOUR = MINUTES * 60;
export const DAY = HOUR * 24;
export const YEAR = DAY * 365;

export const LAUNCH_TIME = "May 10 2024 22:00:00 UTC+8";
export const PANCAKE_LAUNCH_TIME = "Aug 18 2023 05:00:00 UTC";
export const STAKING_TIME = "Aug 18 2023 05:00:00 UTC";
export const numeral = require("numeral");

export const checkTime = (time = LAUNCH_TIME) => {
  // return true;
  let now = new Date().getTime() / 1000;
  let deadline = new Date(time).getTime() / 1000;
  if (now >= deadline) {
    return true;
  }
  return false;
};

export const reduceAddress = (addr) => {
  if (addr) {
    return (
      addr.substring(0, 7) +
      "..." +
      addr.substring(addr.length - 5, addr.length)
    );
  }
};

export const addLeadingZero = (str, targetLen = 2) => {
  str = str.toString();
  const len = str.length;
  return "0".repeat(targetLen - len) + str;
};

export const date2CountdownString = (date) => {
  let n = date.toUTCString();
  let timeString =
    n.substring(0, 3) +
    n.substring(7, 12) +
    n.substring(5, 7) +
    n.substring(11, n.length - 4) +
    " UTC";
  return timeString;
};

export const shortenDate = (dateTime) => {
  const date = dateTime.getDate();
  const month = dateTime.getMonth();
  const year = dateTime.getFullYear();

  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const seconds = dateTime.getSeconds();

  let timeString = `${addLeadingZero(parseInt(month) + 1)}-${addLeadingZero(
    date
  )}-${year}  ${addLeadingZero(hours)}:${addLeadingZero(
    minutes
  )}:${addLeadingZero(seconds)}`;
  return timeString;
};

export const getRefPath = () => {
  const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
  const refAddr = urlParams.get("ref");
  if (Utils.web3 && Utils.web3.utils.isAddress(refAddr)) {
    return `?ref=${refAddr}`;
  }
  return "";
};

const PAGE = 100;
export const multiCall = async (abi, calls) => {
  try {
    const multi = Utils.multiCall;
    const itf = new Interface(abi);
    let res = [];

    if (calls.length > PAGE) {
      let i = 0;
      while (i < calls.length / PAGE) {
        const newCalls = calls.slice(i * PAGE, PAGE * (i + 1));
        const calldata = newCalls.map((call) => [
          call[0].toLowerCase(),
          itf.encodeFunctionData(call[1], call[2]),
        ]);
        const { returnData } = await multi.methods.aggregate(calldata).call();
        i++;
        res = res.concat(
          returnData.map((call, index) =>
            itf.decodeFunctionResult(newCalls[index][1], call)
          )
        );
      }
    } else {
      const calldata = calls.map((call) => [
        call[0].toLowerCase(),
        itf.encodeFunctionData(call[1], call[2]),
      ]);
      const { returnData } = await multi.methods.aggregate(calldata).call();
      res = returnData.map((call, i) =>
        itf.decodeFunctionResult(calls[i][1], call)
      );
    }
    return res;
  } catch (e) {
    console.log(e);
  }
};

export const showMaxDecimal = (value, decimals) => {
  if (value === "0.") {
    return value;
  }
  if (isNaN(value) || value === "") {
    return "";
  }
  const unit = Math.pow(10, decimals);
  return Math.floor(parseFloat(value) * unit) / unit;
};

export const validateAddr = async (addr) => {
  if (!Utils.web3.utils.isAddress(addr)) {
    return false;
  }

  return (
    addr !== "0x0000000000000000000000000000000000000000" &&
    !addr
      .toLowerCase()
      .includes(Utils.web3.currentProvider.selectedAddress.toLowerCase())
  );
};

export const getGasPrice = async () => {
  if (!Utils.web3) {
    return 5000000000;
  }
  return parseInt(await Utils.web3.eth.getGasPrice()) + 1000000000;
};
